@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy'),
    url('./Assets/fonts//Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src:
    local('Gilroy'),
    url('./Assets/fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 800;
  src:
    local('Gilroy'),
    url('./Assets/fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src:
    local('Plus Jakarta Sans'),
    url('./Assets/fonts/PlusJakartaSans.ttf') format('truetype');
}

@font-face {
  font-family: 'Galvji';
  src:
    local('Galvji'),
    url('./Assets/fonts/galvji.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url('./Assets/fonts/Inter-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src:
    local('Inter'),
    url('./Assets/fonts/Inter-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src:
    local('Inter'),
    url('./Assets/fonts/Inter-Bold.otf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
